import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import Navbar from "../components/Navbar";
import Geral from "../components/Profile/Geral";
import Notificacao from "../components/Profile/Notificacao";
import SideMenu from "../components/SideMenu";

function Profile() {
  const { t } = useTranslation();
  const [activeMenu, setActiveMenu] = useState("Geral");

  const renderContent = () => {
    switch (activeMenu) {
      case "Geral":
        return <Geral />;
      case "Notificações":
        return <Notificacao />;
      default:
        return null;
    }
  };

  return (
    <div id="wrapper" style={{ backgroundColor: "#043A5E" }}>
      <SideMenu />

      <div id="page-wrapper" style={{ backgroundColor: "#FCFCFD" }}>
        <Navbar />

        <div
          className="pl-4 mt-4 custom-normal"
          style={{ fontSize: "38px", fontWeight: "600" }}
        >
          {t("conta.title")}
        </div>

        <div className="wrapper wrapper-content">
          <div>
            <nav className="navbar navbar-expand-lg navbar-light">
              <ul
                className="nav nav-pills w-100"
                style={{ borderBottom: "1px solid #F3F4F7" }}
              >
                <li className="">
                  <div
                    className={`mr-5 ${
                      activeMenu === "Geral" ? "active-menu" : ""
                    }`}
                    style={{ cursor: "pointer", paddingBottom: "10px" }}
                    onClick={() => setActiveMenu("Geral")}
                  >
                    {t("conta.general")}
                  </div>
                </li>
                <li className="">
                  <div
                    className={`mr-5 ${
                      activeMenu === "Notificações" ? "active-menu" : ""
                    }`}
                    style={{ cursor: "pointer", paddingBottom: "10px" }}
                    onClick={() => setActiveMenu("Notificações")}
                  >
                    {t("conta.notifications")}
                  </div>
                </li>
              </ul>
            </nav>

            <div className="content" style={{ marginTop: "20px" }}>
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
