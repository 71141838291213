import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiRequest } from "../api/apiRequest";
import Loading from "../components/Loading";
import Navbar from "../components/Navbar";
import SideMenu from "../components/SideMenu";
import HeatmapExample from "../components/StatisticalData/HeatMap";
import NormasPublicadas from "../components/StatisticalData/NormasPublicadas";
import { StatisticalDataTable } from "../components/StatisticalData/StatisticalDataTable";

function StatisticalData() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const currenthMonth = new Date().getMonth() + 1;
  const [errorMonth, setErrorYear] = useState(null);
  const [errorYear, setErrorMonth] = useState(null);
  const [loadingMonth, setLoadingMonth] = useState(true);
  const [loadingYear, setLoadingYear] = useState(true);
  const [annualFilter, setAnnualFilter] = useState(currentYear);
  const [yearFilter, setYearFilter] = useState(currentYear);
  const [monthFilter, setMonthFilter] = useState(currenthMonth);
  const [annualData, setAnnualData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [filterDataToHeatMap, setFilterDataToHeatMap] = useState([]);
  const statesBrazil = [
    "AC",
    "AL",
    "AM",
    "AP",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MG",
    "MS",
    "MT",
    "PA",
    "PB",
    "PE",
    "PI",
    "PR",
    "RJ",
    "RN",
    "RO",
    "RR",
    "RS",
    "SC",
    "SE",
    "SP",
    "TO",
  ];

  const fetchDataAnual = async () => {
    setLoadingYear(true);
    setErrorYear(null);

    try {
      const response = await apiRequest(
        "GET",
        `/dashboard/dados-estatisticos?ano=${annualFilter}`
      );

      setFilterDataToHeatMap(
        response.data.normas_por_estado.filter((item) =>
          statesBrazil.includes(item.estado)
        )
      );
      setAnnualData(response.data);
    } catch (error) {
      setErrorYear(error);
    } finally {
      setLoadingYear(false);
    }
  };

  useEffect(() => {
    fetchDataAnual();
  }, []);

  const fetchDataMesAno = async () => {
    setLoadingMonth(true);
    setErrorMonth(null);

    try {
      const response = await apiRequest(
        "GET",
        `/dashboard/dados-estatisticos-mes?ano=${yearFilter}&mes=${monthFilter}`
      );

      setMonthlyData(response.data);
    } catch (error) {
      setErrorMonth(error);
    } finally {
      setLoadingMonth(false);
    }
  };

  useEffect(() => {
    fetchDataMesAno();
  }, []);

  return (
    <div id="wrapper" style={{ backgroundColor: "#043A5E" }}>
      <SideMenu />

      <div id="page-wrapper" style={{ backgroundColor: "#FCFCFD" }}>
        <Navbar />

        <div
          className="pl-4 mt-4 custom-normal"
          style={{ fontSize: "34px", fontWeight: "600" }}
        >
          {t("dadosEstatisticos.title")}
        </div>

        {loadingMonth && <Loading />}
        {!loadingMonth && (
          <div>
            {/* Filtro de mês e ano */}
            <div
              className="bg-white shadow-sm d-flex m-4 row"
              style={{ borderRadius: "1rem" }}
            >
              <div class="container mt-5 col-6">
                <h3 className="mb-4">
                  {t("dadosEstatisticos.selectMonthAndYear")}
                </h3>
                <form className="d-flex">
                  <div class="form-group">
                    <input
                      type="month"
                      class="form-control"
                      id="mesAno"
                      name="mesAno"
                      value={`${yearFilter}-${monthFilter}`}
                      onChange={(e) => {
                        const [ano, mes] = e.target.value.split("-");
                        setYearFilter(ano);
                        setMonthFilter(mes);
                      }}
                    />
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style={{ height: "35px", marginLeft: "15px" }}
                    onClick={() => fetchDataMesAno()}
                  >
                    {t("dadosEstatisticos.toFilter")}
                  </button>
                </form>
              </div>

              <div className="d-flex justify-content-between col-6">
                <NormasPublicadas
                  title={t("dadosEstatisticos.publishedRegulationsByYear")}
                  staticDatas={monthlyData}
                />
              </div>
            </div>

            {/* Tabela de normas por dia do mês */}
            {monthlyData.normas_por_mes_ano &&
            monthlyData.normas_por_mes_ano.length > 0 ? (
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <StatisticalDataTable
                  title={t("dadosEstatisticos.dayMonth")}
                  data={monthlyData.normas_por_mes_ano}
                />
              </div>
            ) : (
              <div className="d-flex" style={{ justifyContent: "center" }}>
                {t("dadosEstatisticos.noData")}
              </div>
            )}
            {errorMonth && (
              <div className="d-flex" style={{ justifyContent: "center" }}>
                {t("dadosEstatisticos.errorMessage")}
              </div>
            )}
          </div>
        )}

        {/* Linha divisória */}
        <hr
          sytle={{
            border: "0",
            height: "1px",
            backgroundColor: "#ccc",
            margin: "20px 0",
          }}
        />

        {loadingYear && <Loading />}
        {!loadingYear && (
          <div>
            {/* Filtro de ano */}
            <div
              className="bg-white shadow-sm d-flex m-4 row"
              style={{ borderRadius: "1rem" }}
            >
              <div class="container mt-5 col-6">
                <h3 className="mb-4">{t("dadosEstatisticos.selectYear")}</h3>
                <form className="d-flex">
                  <input
                    type="number"
                    class="form-control"
                    id="ano"
                    name="ano"
                    min="2000"
                    max="2099"
                    onChange={(e) => {
                      setAnnualFilter(e.target.value);
                    }}
                    value={annualFilter}
                    style={{ width: "100px" }}
                  ></input>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style={{ height: "35px", marginLeft: "15px" }}
                    onClick={() => fetchDataAnual()}
                  >
                    {t("dadosEstatisticos.toFilter")}
                  </button>
                </form>
              </div>

              <div className="d-flex justify-content-between col-6">
                <NormasPublicadas
                  title={t("dadosEstatisticos.publishedRegulationsByMonth")}
                  staticDatas={annualData}
                />
              </div>
            </div>

            {/* Tabela de normas por mês do ano */}
            {annualData.normas_por_mes_ano ? (
              <div className="d-flex" style={{ justifyContent: "center" }}>
                <StatisticalDataTable
                  title={t("dadosEstatisticos.monthYear")}
                  data={annualData.normas_por_mes_ano}
                />
              </div>
            ) : (
              <div className="d-flex" style={{ justifyContent: "center" }}>
                {t("dadosEstatisticos.noData")}
              </div>
            )}
            {errorYear && (
              <div className="d-flex" style={{ justifyContent: "center" }}>
                {t("dadosEstatisticos.errorMessage")}
              </div>
            )}

            <div
              className="d-flex mb-4 row "
              style={{
                justifyContent: "center",
              }}
            >
              <div
                className="col-10 mb-4"
                style={{
                  borderRadius: "1rem",
                }}
              >
                <div
                  className="mt-4 p-4 border-0 shadow-sm bg-white"
                  style={{ borderRadius: "1rem" }}
                >
                  <div
                    className="custom-normal"
                    style={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    {t("dadosEstatisticos.byState")}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: "59%", height: "400px" }}
                    >
                      <HeatmapExample normasPorEsfera={filterDataToHeatMap} />
                    </div>

                    <div style={{ width: "35%" }}>
                      <div className="text-black-50">Total</div>
                      <h2
                        className="mt-1 custom-normal"
                        style={{ fontSize: "20px", fontWeight: "600" }}
                      >
                        {annualData.total_normas_por_estado}
                      </h2>

                      {filterDataToHeatMap ? (
                        filterDataToHeatMap.slice(0, 5).map((item) => (
                          <div className="d-flex align-items-center mt-4">
                            <div style={{ width: "75%" }}>
                              <div className="custom-normal mb-2">
                                {item.estado}
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="progress-bar-container">
                                  <div
                                    className="progress-bar"
                                    style={{ width: `${item.porcentagem}%` }}
                                  >
                                    <span
                                      style={{ padding: "0 10px 0 0" }}
                                    ></span>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    margin: "0 0 0 1rem",
                                  }}
                                >
                                  {Math.round(item.porcentagem)}%
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="d-flex align-items-center mt-4">
                          <div style={{ width: "75%" }}>
                            <div className="custom-normal mb-2">Brasil</div>
                            <div className="d-flex align-items-center">
                              <div className="progress-bar-container">
                                <div
                                  className="progress-bar"
                                  style={{ width: `${0}%` }}
                                >
                                  <span
                                    style={{ padding: "0 10px 0 0" }}
                                  ></span>
                                </div>
                              </div>
                              <div
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  margin: "0 0 0 1rem",
                                }}
                              >
                                0%
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default StatisticalData;
