import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiRequest } from "../../api/apiRequest";

const styles = {
  inputContainer: { position: "relative", width: "100%" },
  input: {
    width: "100%",
    padding: "20px 10px 10px 10px",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    outline: "none",
  },
  label: {
    position: "absolute",
    top: "10px",
    left: "10px",
    color: "#aaa",
    fontSize: "10px",
    pointerEvents: "none",
    transform: "translateY(-50%)",
    transition: "0.2s ease all",
  },
};

function Geral() {
  const { t } = useTranslation();
  const [userData, setUserData] = useState({
    nome: "",
    email: "",
    senha: "",
    whatsapp: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiRequest("GET", `/minha-conta`);

        setUserData({
          nome: response.data.nome,
          email: response.data.email,
          // não tem o campo de senha
          whatsapp: response.data.whatsapp,
        });
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await apiRequest("PUT", `/minha-conta-update`, userData);

      alert(t("conta.alertSuccess"));
    } catch (err) {
      setError(err);
      alert(t("conta.alertFail"));
    }
  };

  if (loading) {
    return <div> {t("conta.loading")}</div>;
  }

  if (error) {
    return (
      <div>
        {t("conta.error")} {error.message}
      </div>
    );
  }

  return (
    <div
      className="p-4 bg-white shadow-sm d-flex justify-content-between"
      style={{ borderRadius: "1rem" }}
    >
      <div style={{ fontSize: "14px", fontWeight: "600", color: "#111927" }}>
        {t("conta.details")}
      </div>
      <form
        onSubmit={handleSubmit}
        className="d-flex flex-column align-items-start"
        style={{ width: "50%" }}
      >
        <div
          className="mt-4 d-flex align-items-center w-100"
          style={{ position: "relative" }}
        >
          <div style={styles.inputContainer}>
            <input
              type="text"
              id="nome"
              name="nome"
              value={userData.nome}
              onChange={handleChange}
              style={styles.input}
            />
            <label htmlFor="nome" style={styles.label}>
              {t("conta.fullName")}
            </label>
          </div>
          <div
            className="custom-normal"
            style={{ position: "absolute", right: "-5rem" }}
          >
            <button type="submit" className="btn btn-success">
              {t("conta.save")}
            </button>
          </div>
        </div>
        <div className="mt-4 d-flex align-items-center w-100">
          <div style={styles.inputContainer}>
            <input
              type="email"
              id="email"
              name="email"
              value={userData.email}
              onChange={handleChange}
              style={styles.input}
            />
            <label htmlFor="email" style={styles.label}>
              {t("conta.email")}
            </label>
          </div>
        </div>

        <div className="mt-4 d-flex align-items-center w-100">
          <div style={styles.inputContainer}>
            <input
              type="password"
              id="senha"
              name="senha"
              value=""
              onChange={handleChange}
              style={styles.input}
              placeholder="Digite uma nova senha"
            />
            <label htmlFor="senha" style={styles.label}>
              {t("conta.password")}
            </label>
          </div>
        </div>

        <div className="mt-4 d-flex align-items-center w-100">
          <div style={styles.inputContainer}>
            <input
              type="tel"
              id="whatsapp"
              name="whatsapp"
              value={userData.whatsapp}
              onChange={handleChange}
              style={styles.input}
            />
            {/* TODO: formatar campo de whatsapp */}
            <label htmlFor="whatsapp" style={styles.label}>
              {t("conta.whatsapp")}
            </label>
          </div>
        </div>
      </form>
      <div></div>
    </div>
  );
}

export default Geral;
