import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { apiRequest } from "../../api/apiRequest";
import Loading from "../Loading";

function BibliotecaDeNormas() {
  const { t } = useTranslation();
  const [normasData, setNormasData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const formatDate = (dateString) => {
    const [datePart] = dateString.split(" ");
    const [year, month, day] = datePart.split("-");
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const normasResponse = await apiRequest(
          "GET",
          `/dashboard/normas?pagina=0&limite_quantidade=5`
        );
        setNormasData(normasResponse.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) return <p className="mx-3">{t("dashboard.errorLibrary")}</p>;

  return (
    <div>
      <div className="">
        <div className="">
          <table className="table table-borderless">
            <thead className="bg-light">
              <tr>
                <th className="pl-3 font-weight-light" style={{ width: "60%" }}>
                  {t("dashboard.tableLastAnalyses.name")}
                </th>
                <th className="font-weight-light">
                  {t("dashboard.tableLastAnalyses.source")}
                </th>
                <th className="font-weight-light">
                  {t("dashboard.tableLastAnalyses.date")}
                </th>
              </tr>
            </thead>
            <tbody>
              {normasData.normas && normasData.normas.length > 0 ? (
                normasData.normas.map((item) => (
                  <tr key={item.id}>
                    <td className="pl-3 font-weight-light">
                      {" "}
                      <Link
                        to={`/documento/norma/${item.id}`}
                        style={{ color: "#232730" }}
                      >
                        {item.nome}
                      </Link>
                    </td>
                    <td className="font-weight-light">
                      {item.areaatuacao_nome}
                    </td>
                    <td className="font-weight-light">
                      {formatDate(item.arquivo_criado)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    {t("dashboard.noNormsFound")}
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot className="pl-4 mt-2 d-flex">
              {normasData.normas && normasData.normas.length > 0 ? (
                <div
                  style={{
                    marginBottom: "1rem",
                    display: "flex",
                  }}
                >
                  <Link
                    to={"/biblioteca"}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {t("dashboard.seeMore")}
                  </Link>
                  <div
                    className="ml-1 d-flex justify-content-start align-items-center"
                    style={{ fontSize: "1.3rem" }}
                  >
                    <IoIosArrowRoundForward />
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

export default BibliotecaDeNormas;
