import { Route, HashRouter as Router, Routes } from "react-router-dom";
import { InternalScreen } from "./components/Interna/InternalScreen";
import Apresentacoes from "./pages/ApresentacoesMensais";
import Biblioteca from "./pages/Biblioteca";
import Calendario from "./pages/Calendario";
import Profile from "./pages/Conta";
import Dashboard from "./pages/Dashboard";
import Informativos from "./pages/Informativos";
import Leituras from "./pages/LeiturasTecnicas";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import StatisticalData from "./pages/StatisticalData";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route exact path="/" element={<Login />} />
        <Route path="/reset/password" element={<ResetPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/biblioteca" element={<Biblioteca />} />
        <Route path="/calendario" element={<Calendario />} />
        <Route path="/data" element={<StatisticalData />} />
        <Route path="/apresentacoes" element={<Apresentacoes />} />
        <Route path="/informativos" element={<Informativos />} />
        {/* Entrará em uma versão futura */}
        {/* <Route path="/leis" element={<LeisTraduzidas />} /> */}
        <Route path="/leituras" element={<Leituras />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/documento/:path/:id" element={<InternalScreen />} />
      </Routes>
    </Router>
  );
}

export default App;
