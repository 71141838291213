import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';

import accenture from '../img/login/accenture.png';
import atat from '../img/login/AT&T.png';
import aws from '../img/login/aws.png';
import bolt from '../img/login/bolt.png';
import detalhe from '../img/login/detalhe.png';
import samsung from '../img/login/samsung.png';
import visma from '../img/login/visma.png';
import logo from '../img/logo-taxupdate.png';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ email: false, password: false });

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const emailError = email === '';
        const passwordError = password === '';

        setErrors({ email: emailError, password: passwordError, messageEmail: "O campo email é obrigatório", messagePassword: "O campo senha é obrigatório"});

        if (!emailError && !passwordError) {
            try {
                const response = await axios.post(`${API_BASE_URL}/login`, { email: email, senha: password });
                const { token } = response.data
                localStorage.setItem('token', token);
                navigate('/dashboard');
            } catch (error) {
                setErrors({ email: true, password: true, messageEmail: "Email ou senha incorretos", messagePassword: "Email ou senha incorretos" })
            }
        }
    };

    return (
        <div className="">
            <div className="d-flex" style={{height: '100vh'}}>
                <div className="d-flex flex-column align-items-center justify-content-center" style={{
                    width: '60%',
                    backgroundColor: '#043A5E',
                    backgroundImage: `url(${detalhe})`,
                    backgroundPosition: '330px center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative'
                }}>
                    <img
                        src={logo}
                        alt="Imagem decorativa"
                        style={{position: 'absolute', top: '5%', left: '10%', width: '124px', height: 'auto'}}
                    />
                    <div style={{width: '80%'}}>
                        <h2 className="" style={{fontSize: '38px', fontWeight: '600', color: '#429A85'}}>Bem Vindo a
                            TaxUpdate!</h2>
                        <div className="text-white"
                             style={{fontSize: '13px', fontWeight: '500', lineHeight: '1.5rem'}}>Simplificamos o
                            acompanhamento da legislação tributária do Brasil<br></br> e mais de 30 países da América
                            Latina.
                        </div>
                        <div className="text-white mt-4 mb-3" style={{fontSize: '13px', fontWeight: '500'}}>Junte-se a
                            empresas inovadoras
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="mr-4"><img src={samsung} alt="SAMSUNG"
                                                       style={{width: '105px', height: 'auto'}}/></div>
                            <div className="mr-4"><img src={visma} alt="VISMA" style={{width: '90px', height: 'auto'}}/>
                            </div>
                            <div className="mr-4"><img src={bolt} alt="Bolt" style={{width: '38px', height: 'auto'}}/>
                            </div>
                            <div className="mr-4"><img src={aws} alt="AWS" style={{width: '40px', height: 'auto'}}/>
                            </div>
                            <div className="mr-4"><img src={accenture} alt="accenture"
                                                       style={{width: '92px', height: 'auto'}}/></div>
                            <div className="mr-4"><img src={atat} alt="AT&T" style={{width: '58px', height: 'auto'}}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column align-items-center justify-content-center bg-white"
                     style={{width: '40%'}}>
                    <div className="custom-normal text-start w-100 pl-5 mb-4"
                         style={{fontSize: '22px', fontWeight: '600'}}>Log in
                    </div>
                    <form className="px-5" role="form" onSubmit={handleSubmit} style={{width: '100%'}}>
                        <div className="form-group mb-4">
                            <input type="email" className="form-control" placeholder="E-mail" value={email}
                                   onChange={(e) => setEmail(e.target.value)}
                                   style={{
                                       padding: '0.6rem 0 0.6rem 0.8rem',
                                       borderRadius: '7px',
                                       borderColor: errors.email ? '#F14437' : '',
                                       borderWidth: errors.email ? '2px' : '1px',
                                   }}
                            />
                            {errors.email && (
                                <small style={{color: '#F14437'}}>{ errors.messageEmail }</small>
                            )}
                        </div>

                        <div className="form-group">
                            <input type="password" className="form-control" placeholder="Senha" value={password}
                                   onChange={(e) => setPassword(e.target.value)}
                                   style={{
                                       padding: '0.6rem 0 0.6rem 0.8rem',
                                       borderRadius: '7px',
                                       borderColor: errors.password ? '#F14437' : '',
                                       borderWidth: errors.email ? '2px' : '1px',
                                   }}
                            />
                            {errors.password && (
                                <small style={{color: '#F14437'}}>{ errors.messagePassword }</small>
                            )}
                        </div>

                        <button type="submit" className="btn btn-primary block full-width m-b"
                                style={{borderRadius: '7px'}}>
                            Continuar
                        </button>

                        <a href="/#/reset/password" className="btn btn-link">
                            <small >Esqueceu sua senha?</small>
                        </a>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
