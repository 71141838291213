import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiRequest } from "../../api/apiRequest";

function Switch({ fieldName, notificationStatus }) {
  const { t } = useTranslation();
  const [isOn, setIsOn] = useState(notificationStatus);

  const handleSubmit = async () => {
    try {
      await apiRequest("PUT", `/minha-conta-update`, {
        [fieldName]: Number(!isOn),
      });

      alert(t("conta.alertSuccess"));
    } catch (err) {
      alert(t("conta.alertFail"));
    }
  };

  const toggleSwitch = () => {
    handleSubmit();
    setIsOn(Number(!isOn));
  };

  const styles = {
    switchContainer: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    switch: {
      width: "40px",
      height: "16px",
      backgroundColor: isOn ? "#B1B3F8" : "#ccc",
      borderRadius: "25px",
      position: "relative",
      transition: "background-color 0.3s",
    },
    switchToggle: {
      width: "20px",
      height: "20px",
      backgroundColor: isOn ? "#6266F0" : "#6C737F",
      borderRadius: "50%",
      position: "absolute",
      top: "-2px",
      left: isOn ? "20px" : "0px",
      transition: "left 0.3s",
    },
    switchLabel: { marginLeft: "10px", fontSize: "14px" },
  };

  return (
    <div style={styles.switchContainer} onClick={toggleSwitch}>
      <div style={styles.switch}>
        <div style={styles.switchToggle}></div>
      </div>
      <span style={styles.switchLabel}></span>
    </div>
  );
}

function Notificacao() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({
    notificacaoEmail: 0,
    notificacaoWhatsapp: 0,
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await apiRequest("GET", `/minha-conta`);

        setUserData({
          notificacaoEmail: response.data.receber_diario_email,
          notificacaoWhatsapp: response.data.receber_diario_whatsapp,
        });
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return <div> {t("conta.loading")}</div>;
  }

  if (error) {
    return (
      <div>
        {t("conta.error")} {error.message}
      </div>
    );
  }

  return (
    <div style={{ borderRadius: "1rem" }}>
      <div
        className="bg-white shadow-sm d-flex justify-content-between"
        style={{ borderBottom: "1px solid #F3F4F7" }}
      >
        <div
          className="p-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            width: "50%",
            color: "#111927",
          }}
        >
          {t("conta.email")}
        </div>
        <div
          className="p-4"
          style={{ width: "50%", borderBottom: "1px solid #F3F4F7" }}
        >
          <div className="d-flex justify-content-between align-items-center pb-3">
            <div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#6C737F",
                }}
              >
                {t("conta.emailDescription")}
              </div>
            </div>
            <div>
              <Switch
                fieldName="receber_diario_email"
                notificationStatus={userData.notificacaoEmail}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white shadow-sm d-flex justify-content-between">
        <div
          className="p-4"
          style={{
            fontSize: "14px",
            fontWeight: "600",
            width: "50%",
            color: "#111927",
          }}
        >
          {t("conta.whatsapp")}
        </div>
        <div
          className="p-4"
          style={{ width: "50%", borderBottom: "1px solid #F3F4F7" }}
        >
          <div className="d-flex justify-content-between align-items-center pb-3">
            <div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#6C737F",
                }}
              >
                {t("conta.whatsappDescription")}
              </div>
            </div>
            <div>
              <Switch
                fieldName="receber_diario_whatsapp"
                notificationStatus={userData.notificacaoWhatsapp}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notificacao;
