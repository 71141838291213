import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsDownload } from "react-icons/bs";
import { LuArrowRight } from "react-icons/lu";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { apiRequest } from "../api/apiRequest";
import Loading from "../components/Loading";
import Navbar from "../components/Navbar";
import SideMenu from "../components/SideMenu";

function Apresentacoes() {
  const { t } = useTranslation();
  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };
  const currentDate = new Date();
  const startDate = new Date();
  startDate.setFullYear(currentDate.getFullYear() - 1);
  const [dataDe, setDataDe] = useState(formatDate(startDate));
  const [dataAte, setDataAte] = useState(formatDate(currentDate));
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [hasMore, setHasMore] = useState(true);

  const startItem = page * itemsPerPage + 1;
  const endItem = startItem + data.length - 1;

  const pagination = "&pagina=" + page;
  const dateFrom = "&data_de=" + dataDe;
  const dateTo = "&data_ate=" + dataAte;

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    setHasMore(true);
    try {
      const response = await apiRequest(
        "GET",
        `/apresentacoes?limite_quantidade=${itemsPerPage}${pagination}${dateFrom}${dateTo}&tabela_nome=apresentacaomensal`
      );

      const fetchedData = response.data.newsletters || [];
      setData(fetchedData);
      setHasMore(fetchedData.length === itemsPerPage);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      setError(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, itemsPerPage]);

  const fetchPdf = async (id) => {
    try {
      const response = await apiRequest(
        "GET",
        `/apresentacao/${id}?pdf=1`,
        "",
        "",
        "blob"
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `documento_${id}.pdf`;
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {}
  };

  return (
    <div id="wrapper" style={{ backgroundColor: "#043A5E" }}>
      <SideMenu />

      <div id="page-wrapper" style={{ backgroundColor: "#FCFCFD" }}>
        <Navbar />

        <div
          className="pl-2 mt-4 mb-4 custom-normal"
          style={{ fontSize: "38px", fontWeight: "600" }}
        >
          {t("apresentacoes.title")}
        </div>

        <div
          className="bg-white shadow-sm d-flex flex-column justify-content-between"
          style={{ borderRadius: "1rem" }}
        >
          {/* Filtro */}
          <div className="form mx-3 my-3">
            <h3 className="control-label mb-3"> {t("apresentacoes.filter")}</h3>
            <div className="d-flex" style={{ gap: "10px" }}>
              <div className="d-flex align-items-center">
                {t("apresentacoes.dateFrom")}:
              </div>
              <div style={{ position: "relative" }}>
                <input
                  type="date"
                  value={dataDe}
                  onChange={(e) => setDataDe(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "13px",
                    paddingRight: "13px",
                    border: "1px solid #E5E7EB",
                    borderRadius: "7px",
                    fontSize: "14px",
                    color: "#6C737F",
                  }}
                />
              </div>
              <div className="d-flex align-items-center">
                {t("apresentacoes.dateTo")}:
              </div>
              <div style={{ position: "relative" }}>
                <input
                  type="date"
                  value={dataAte}
                  onChange={(e) => setDataAte(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "13px",
                    paddingRight: "13px",
                    border: "1px solid #E5E7EB",
                    borderRadius: "7px",
                    fontSize: "14px",
                    color: "#6C737F",
                  }}
                />
              </div>
              <div className="d-flex align-items-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ height: "35px" }}
                  onClick={() => fetchData()}
                >
                  {t("apresentacoes.toFilter")}
                </button>
              </div>
            </div>
          </div>
          <div className="content" style={{ marginTop: "20px" }}>
            {loading && <Loading />}
            {error && <p className="mx-3">{t("apresentacoes.errorMessage")}</p>}
            {!loading && (
              <div>
                <div className="mb-3">
                  <div
                    className="d-flex justify-content-between py-3 px-3 bg-light row"
                    style={{ fontSize: "13px", fontWeight: "600" }}
                  >
                    <div className="col-10">{t("apresentacoes.reports")}</div>
                    <div className="col-2">{t("apresentacoes.actions")}</div>
                  </div>

                  {data && data.length > 0
                    ? data.map((item) => (
                        <div
                          className="d-flex py-3 px-3 row"
                          key={item.id}
                          style={{
                            fontSize: "13px",
                            fontWeight: "600",
                            borderBottom: "1px solid #F3F4F7",
                          }}
                        >
                          <div className="d-flex align-items-center col-10">
                            <div
                              className="mr-3 d-flex flex-column justify-content-center align-items-center"
                              style={{
                                width: "60px",
                                height: "60px",
                                borderRadius: "15px",
                                backgroundColor: "#E5E7EB",
                              }}
                            >
                              <div
                                style={{ fontSize: "14px", fontWeight: "600" }}
                              >
                                {new Date(item.criado)
                                  .toLocaleString("default", { month: "short" })
                                  .toUpperCase()}
                              </div>
                              <div
                                style={{ fontSize: "15px", fontWeight: "700" }}
                              >
                                {new Date(item.enviado).getDate()}
                              </div>
                            </div>
                            <div>
                              <div>{item.assunto}</div>
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center col-2"
                            style={{ fontSize: "22px" }}
                          >
                            <div
                              className="mr-3"
                              style={{ cursor: "pointer", color: "#232730" }}
                              onClick={() => fetchPdf(item.id)}
                            >
                              <BsDownload />
                            </div>
                            <Link
                              to={`/documento/apresentacao/${item.id}`}
                              style={{ color: "#232730" }}
                            >
                              <LuArrowRight />
                            </Link>
                          </div>
                        </div>
                      ))
                    : !loading &&
                      !error && (
                        <div style={{ textAlign: "center", padding: "20px" }}>
                          {t("apresentacoes.noData")}
                        </div>
                      )}
                  {/* Paginação */}
                  <div className="d-flex justify-content-end align-items-center py-2">
                    <div className="mr-4">
                      {" "}
                      {t("apresentacoes.documentsPerPage")}
                    </div>
                    <select
                      className="mr-4"
                      style={{ border: "none" }}
                      value={itemsPerPage}
                      onChange={(e) => {
                        setItemsPerPage(parseInt(e.target.value));
                        setPage(0);
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                    </select>
                    <div className="mr-4">
                      {t("apresentacoes.showing")} {startItem}-{endItem}
                    </div>
                    <div
                      className="mr-4"
                      style={{
                        fontSize: "20px",
                        cursor: page === 0 ? "default" : "pointer",
                        opacity: page === 0 ? 0.5 : 1,
                      }}
                      onClick={() => {
                        if (page > 0) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      <RiArrowLeftSLine />
                    </div>
                    <div
                      className="mr-4"
                      style={{
                        fontSize: "20px",
                        cursor: hasMore ? "pointer" : "default",
                        opacity: hasMore ? 1 : 0.5,
                      }}
                      onClick={() => {
                        if (hasMore) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      <RiArrowRightSLine />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Apresentacoes;
