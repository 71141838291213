import L from "leaflet";
import "leaflet.heat";
import "leaflet/dist/leaflet.css";
import React from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";

const Heatmap = ({ points }) => {
  const map = useMap();

  React.useEffect(() => {
    L.heatLayer(points, {
      radius: 20,
      blur: 15,
      maxZoom: 2,
      gradient: {
        0.2: "blue",
        0.4: "lime",
        0.6: "yellow",
        0.8: "orange",
        1: "red",
      },
    }).addTo(map);
  }, [map, points]);

  return null;
};

const HeatmapExample = ({ normasPorEsfera }) => {
  const center = [-14.235004, -51.92528];
  console.log(normasPorEsfera);
  const estadoCoordenadas = {
    AC: [-8.77, -70.55],
    AL: [-9.71, -35.73],
    AM: [-3.07, -61.66],
    AP: [1.41, -51.77],
    BA: [-12.96, -38.51],
    CE: [-3.71, -38.54],
    DF: [-15.83, -47.86],
    ES: [-19.19, -40.34],
    GO: [-16.64, -49.31],
    MA: [-2.55, -44.3],
    MT: [-12.64, -55.42],
    MS: [-20.51, -54.54],
    MG: [-18.1, -44.38],
    PA: [-5.53, -52.29],
    PB: [-7.06, -35.55],
    PR: [-24.89, -51.55],
    PE: [-8.28, -35.07],
    PI: [-8.28, -43.68],
    RJ: [-22.84, -43.15],
    RN: [-5.22, -36.52],
    RO: [-11.22, -62.8],
    RS: [-30.01, -51.22],
    RR: [1.89, -61.22],
    SC: [-27.33, -49.44],
    SE: [-10.9, -37.07],
    SP: [-23.55, -46.64],
    TO: [-10.25, -48.25],
  };

  const populationData = normasPorEsfera
    .map((esfera) => {
      const estadoSigla = esfera.estado;
      const coords = estadoCoordenadas[estadoSigla];

      return coords ? [...coords, esfera.porcentagem / 100 + 0.5] : null;
    })
    .filter(Boolean);

  return (
    <MapContainer
      center={center}
      zoom={4}
      style={{ height: "100%", width: "100%" }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Heatmap points={populationData} />
    </MapContainer>
  );
};

export default HeatmapExample;
