import React from "react";
import { useTranslation } from "react-i18next";

export function Switch({ isOn, toggleSwitch }) {
  const { t } = useTranslation();
  const styles = {
    switchContainer: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    switch: {
      width: "50px",
      height: "16px",
      backgroundColor: isOn ? "#809DAF" : "#ccc",
      borderRadius: "25px",
      position: "relative",
      transition: "background-color 0.3s",
    },
    switchToggle: {
      width: "23px",
      height: "23px",
      backgroundColor: "#043A5E",
      borderRadius: "50%",
      position: "absolute",
      top: "-4px",
      left: isOn ? "26px" : "1px",
      transition: "left 0.3s",
    },
    switchLabel: { marginLeft: "10px", fontSize: "14px", color: "#043A5E" },
  };

  return (
    // TODO: tem que enviar essa informação da escolha pra algum lugar
    <div style={styles.switchContainer} onClick={toggleSwitch}>
      <div style={styles.switch}>
        <div style={styles.switchToggle}></div>
      </div>
      <span style={styles.switchLabel}>
        {t("biblioteca.informedStandards")}
      </span>
    </div>
  );
}
