import md5 from "js-md5";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsDownload } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { LuArrowRight } from "react-icons/lu";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { apiRequest } from "../api/apiRequest";
import { FederalFilter } from "../components/Biblioteca/FederalFilter";
import { MunicipalityFilter } from "../components/Biblioteca/MunicipalityFilter";
import { SegmentosFilter } from "../components/Biblioteca/SegmentosFilter";
import { StateFilter } from "../components/Biblioteca/StateFilter";
import { Switch } from "../components/Biblioteca/Switch";
import Loading from "../components/Loading";
import Navbar from "../components/Navbar";
import SideMenu from "../components/SideMenu";

function Biblioteca() {
  const { t } = useTranslation();
  const storedProdutoAtivo = localStorage.getItem("produto_ativo");
  const [normasData, setNormasData] = useState({ normas: [] });
  const [estadosData, setEstadosData] = useState(null);
  const [municipiosData, setMunicipiosData] = useState(null);
  const [impostosData, setImpostosData] = useState(null);
  const [segmentosData, setSegmentosData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedId, setExpandedId] = useState(null);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [estadosSelecionados, setEstadosSelecionados] = useState([]);
  const [municipiosSelecionados, setMunicipiosSelecionados] = useState([]);
  const [impostosSelecionados, setImpostosSelecionados] = useState([]);
  const [segmentosSelecionados, setSegmentosSelecionados] = useState([]);
  const [normasNaoInformadas, setNormasNaoInformadas] = useState(1);
  const [isSmartSearchOpen, setIsSmartSearchOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [hasMore, setHasMore] = useState(true);

  const handleClick = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    setHasMore(true);

    const params = {
      pagina: page,
      limite_quantidade: itemsPerPage,
      normas_nao_informadas: normasNaoInformadas,
    };

    if (dateStart) {
      params.data_de = dateStart;
    }
    if (dateEnd) {
      params.data_ate = dateEnd;
    }
    // if (searchQuery) {
    //   params.search = searchQuery;
    // }
    if (estadosSelecionados.length > 0) {
      params.estados = estadosSelecionados.join(",");
    }
    if (municipiosSelecionados.length > 0) {
      params.municipios = municipiosSelecionados.join(",");
    }
    if (impostosSelecionados.length > 0) {
      params.federal = impostosSelecionados.join(",");
    }
    if (segmentosSelecionados.length > 0) {
      params.segmento_id = segmentosSelecionados.join(",");
    }

    try {
      const normasResponse = await apiRequest(
        "GET",
        `/dashboard/normas`,
        "",
        params
      );
      setNormasData(normasResponse.data);
      setHasMore(normasResponse.data.normas.length === itemsPerPage);
    } catch (error) {
      setError(error);
    }

    try {
      const estadosResponse = await apiRequest("GET", `/filtros/estados`);
      setEstadosData(estadosResponse.data);
    } catch (error) {
      setError(error);
    }

    try {
      const municipiosResponse = await apiRequest("GET", `/filtros/municipios`);
      setMunicipiosData(municipiosResponse.data);
    } catch (error) {
      setError(error);
    }

    try {
      const impostosResponse = await apiRequest("GET", `/filtros/federal`);
      setImpostosData(impostosResponse.data);
    } catch (error) {
      setError(error);
    }

    try {
      const segmentosResponse = await apiRequest("GET", `/filtros/segmentos`);
      setSegmentosData(segmentosResponse.data);
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    dateStart,
    dateEnd,
    estadosSelecionados,
    municipiosSelecionados,
    impostosSelecionados,
    normasNaoInformadas,
    segmentosSelecionados,
    itemsPerPage,
    page,
  ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const startItem = page * itemsPerPage + 1;
  const endItem = startItem + normasData.normas.length - 1;

  const formatDate = (dateString) => {
    const [datePart] = dateString.split(" ");
    const [year, month, day] = datePart.split("-");
    return `${day}/${month}/${year}`;
  };

  const toggleNormasNaoInformadas = () => {
    setNormasNaoInformadas(normasNaoInformadas === 1 ? 0 : 1);
  };

  const NormaLabel = ({ areaAtuacaoNome }) => {
    const renderLabel = () => {
      if (areaAtuacaoNome === "UNIÃO") {
        return (
          <div
            className="py-1 text-center"
            style={{
              backgroundColor: "#E2F7F1",
              color: "#08815B",
              borderRadius: "1rem",
              fontWeight: "600",
              width: "85px",
              letterSpacing: "0.7px",
            }}
          >
            {t("biblioteca.labelType.federal")}
          </div>
        );
      } else if (areaAtuacaoNome.length === 2) {
        return (
          <div
            className="py-1 text-center"
            style={{
              backgroundColor: "#E2EBF7",
              color: "#043A5F",
              borderRadius: "1rem",
              fontWeight: "600",
              width: "85px",
              letterSpacing: "0.5px",
            }}
          >
            {t("biblioteca.labelType.state")}
          </div>
        );
      } else if (areaAtuacaoNome.length > 4) {
        return (
          <div
            className="py-1 text-center"
            style={{
              backgroundColor: "#F6E9E1",
              color: "#E9742E",
              borderRadius: "1rem",
              fontWeight: "600",
              width: "85px",
            }}
          >
            {t("biblioteca.labelType.municipal")}
          </div>
        );
      } else {
        return (
          <div
            className="py-1 text-center"
            style={{
              backgroundColor: "#e1f4f6",
              color: "#2ec7e9",
              borderRadius: "1rem",
              fontWeight: "600",
              width: "85px",
            }}
          >
            {areaAtuacaoNome}
          </div>
        );
      }
    };
    return renderLabel();
  };

  const getHash = (norma) => {
    const nomeArquivo = `documento_norma_${norma}.pdf`;

    return md5(nomeArquivo);
  };

  return (
    <div id="wrapper" style={{ backgroundColor: "#043A5E" }}>
      <SideMenu />

      <div id="page-wrapper" style={{ backgroundColor: "#FCFCFD" }}>
        <Navbar />

        <div className="wrapper wrapper-content">
          <div className="d-flex justify-content-between">
            <div
              className="border-0 shadow-sm bg-white p-4"
              style={{ borderRadius: "1rem", width: "25%" }}
            >
              <div>
                <div
                  className="mb-4 custom-normal"
                  style={{ fontSize: "25px", fontWeight: "600" }}
                >
                  {t("biblioteca.filters")}
                </div>
                <div>
                  <div
                    className="d-flex align-items-center"
                    style={{ position: "relative" }}
                  >
                    <CiSearch
                      style={{
                        position: "absolute",
                        left: "10px",
                        color: "#232730",
                        fontSize: "24px",
                      }}
                    />
                    <input
                      type="text"
                      placeholder={t("biblioteca.keywords")}
                      className="input-pesquisa"
                      onClick={() => setIsSmartSearchOpen(true)}
                    />
                  </div>

                  <div className="mb-4 mt-4">
                    <Switch
                      isOn={normasNaoInformadas === 0}
                      toggleSwitch={toggleNormasNaoInformadas}
                    />
                  </div>

                  <div className="form">
                    <label className="control-label mt-2">
                      {t("biblioteca.publicationDate")}
                    </label>
                    <div>
                      <div>{t("biblioteca.from")}:</div>
                      <div style={{ position: "relative" }}>
                        <input
                          type="date"
                          value={dateStart}
                          onChange={(e) => setDateStart(e.target.value)}
                          style={{
                            width: "100%",
                            padding: "13px",
                            paddingRight: "35px",
                            border: "1px solid #E5E7EB",
                            borderRadius: "7px",
                            fontSize: "14px",
                            color: "#6C737F",
                          }}
                        />
                      </div>
                      <div className="mt-2">{t("biblioteca.to")}:</div>
                      <div style={{ position: "relative" }}>
                        <input
                          type="date"
                          value={dateEnd}
                          onChange={(e) => setDateEnd(e.target.value)}
                          style={{
                            width: "100%",
                            padding: "13px",
                            paddingRight: "35px",
                            border: "1px solid #E5E7EB",
                            borderRadius: "7px",
                            fontSize: "14px",
                            color: "#6C737F",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <SegmentosFilter
                    segmentosData={segmentosData}
                    segmentosSelecionados={segmentosSelecionados}
                    setSegmentosSelecionados={setSegmentosSelecionados}
                  />

                  <FederalFilter
                    impostosData={impostosData}
                    impostosSelecionados={impostosSelecionados}
                    setImpostosSelecionados={setImpostosSelecionados}
                  />

                  <StateFilter
                    estadosData={estadosData}
                    estadosSelecionados={estadosSelecionados}
                    setEstadosSelecionados={setEstadosSelecionados}
                  />

                  {storedProdutoAtivo !== "latam" && (
                    <MunicipalityFilter
                      municipiosData={municipiosData}
                      municipiosSelecionados={municipiosSelecionados}
                      setMunicipiosSelecionados={setMunicipiosSelecionados}
                    />
                  )}
                </div>
              </div>
            </div>

            <div style={{ width: "73%" }}>
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mt-4 mb-4 w-100">
                  <div
                    className="pl-4 custom-normal"
                    style={{ fontSize: "34px", fontWeight: "600" }}
                  >
                    {t("biblioteca.standardsLibrary")}
                  </div>
                </div>

                <div className="bg-white" style={{ borderRadius: "1rem" }}>
                  {loading && (
                    <div style={{ textAlign: "center", padding: "20px" }}>
                      <Loading />
                    </div>
                  )}
                  {error && (
                    <div style={{ textAlign: "center", padding: "20px" }}>
                      Erro: {error.message}
                    </div>
                  )}
                  {!loading && normasData.normas.length <= 0 && !error ? (
                    <div style={{ textAlign: "center", padding: "20px" }}>
                      {t("biblioteca.noDataAvailable")}
                    </div>
                  ) : (
                    <></>
                  )}
                  {!loading &&
                  !error &&
                  normasData.normas &&
                  normasData.normas.length > 0 ? (
                    normasData.normas.map((norma) => (
                      <div
                        className="d-flex flex-column align-items-end"
                        key={norma.id}
                      >
                        <div className="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                          <div style={{ width: "45%" }}>
                            <Link
                              to={`/documento/norma/${norma.id}`}
                              style={{ color: "#232730" }}
                            >
                              <h3> {norma.nome}</h3>
                            </Link>
                            <div
                              className="font-weight-light"
                              onClick={() => handleClick(norma.id)}
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp:
                                  expandedId === norma.id ? "unset" : 3,
                                maxHeight:
                                  expandedId === norma.id ? "none" : "4.5em",
                                cursor: "pointer",
                                transition:
                                  "max-height 0.3s ease, padding-bottom 0.3s ease",
                                paddingBottom:
                                  expandedId === norma.id ? "2rem" : "1rem",
                              }}
                            >
                              {norma.resumo}
                            </div>
                          </div>
                          <div className="font-weight-light">
                            {formatDate(norma.criado)}
                          </div>

                          <NormaLabel
                            areaAtuacaoNome={norma.areaatuacao_nome}
                          />

                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ fontSize: "22px" }}
                          >
                            <a
                              className="mr-3"
                              style={{ cursor: "pointer", color: "#232730" }}
                              href={`http://www.taxupdate.co/norma/pdf/${
                                norma.id
                              }/${getHash(norma.id)}`}
                              target="_blank"
                            >
                              <BsDownload />
                            </a>
                            <div>
                              <Link
                                to={`/documento/norma/${norma.id}`}
                                style={{ color: "#232730" }}
                              >
                                <LuArrowRight />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div
                          className="border-bottom"
                          style={{ width: "97%" }}
                        ></div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
                {/* Paginação */}
                <div className="d-flex justify-content-end align-items-center py-2">
                  <div className="mr-4">
                    {" "}
                    {t("biblioteca.documentsPerPage")}
                  </div>
                  <select
                    className="mr-4"
                    style={{ border: "none" }}
                    value={itemsPerPage}
                    onChange={(e) => {
                      setItemsPerPage(parseInt(e.target.value));
                      setPage(0);
                    }}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                  </select>
                  <div className="mr-4">
                    {t("biblioteca.showing")} {startItem}-{endItem}
                  </div>
                  <div
                    className="mr-4"
                    style={{
                      fontSize: "20px",
                      cursor: page === 0 ? "default" : "pointer",
                      opacity: page === 0 ? 0.5 : 1,
                    }}
                    onClick={() => {
                      if (page > 0) {
                        setPage(page - 1);
                      }
                    }}
                  >
                    <RiArrowLeftSLine />
                  </div>
                  <div
                    className="mr-4"
                    style={{
                      fontSize: "20px",
                      cursor: hasMore ? "pointer" : "default",
                      opacity: hasMore ? 1 : 0.5,
                    }}
                    onClick={() => {
                      if (hasMore) {
                        setPage(page + 1);
                      }
                    }}
                  >
                    <RiArrowRightSLine />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSmartSearchOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              width: "900px",
              maxWidth: "90%",
              position: "relative",
              maxHeight: "700px",
              minHeight: "450px",
              height: "70%",
            }}
          >
            <button
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                fontSize: "18px",
                border: "none",
                background: "none",
              }}
              onClick={() => setIsSmartSearchOpen(false)}
            >
              &times;
            </button>
            <h2 style={{ marginTop: "0" }}>{t("biblioteca.keywords")}</h2>
            <div
              style={{
                position: "relative",
                height: "calc(100% - 30px)",
                width: "100%",
              }}
            >
              <iframe
                src="https://www.chatbase.co/chatbot-iframe/zrgXbiFlML0o08qw6cfSk"
                width="100%"
                height="100%"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Biblioteca;
