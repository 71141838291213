import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiRequest } from "../../api/apiRequest";
import grafico1 from "../../img/dashboard/graf1.png";
import grafico2 from "../../img/dashboard/graf2.png";
import grafico3 from "../../img/dashboard/graf3.png";
import Loading from "../Loading";

function DadosEstatisticos() {
  const { t } = useTranslation();
  const [staticDatas, setStaticDatas] = useState(null);
  const [error, setError] = useState(null);
  const storedProdutoAtivo = localStorage.getItem("produto_ativo");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiRequest(
          "GET",
          `/dashboard/dados-estatisticos`
        );

        setStaticDatas(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, []);

  if (error)
    return <p className="mx-3">{t("dashboard.errorStatisticalData")}</p>;

  return (
    <div>
      <h3>{t("dashboard.statisticalData")}</h3>
      <div className="row">
        <div className="col-4">
          <div
            className="p-4 bg-white border-0 shadow-sm text-center d-flex justify-content-between"
            style={{
              borderRadius: "1rem",
              height: "120px",
              backgroundImage: `url(${grafico1})`,
              backgroundSize: "40% auto",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 24px center",
            }}
          >
            <div className="d-flex flex-column align-items-start">
              <div className="text-black-50">{t("dashboard.federal")}</div>
              <h2
                className="mt-1"
                style={{ fontSize: "18px", fontWeight: "600" }}
              >
                {staticDatas ? staticDatas.normas_federal : <Loading />}
              </h2>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div
            className="p-4 bg-white border-0 shadow-sm text-center d-flex justify-content-between "
            style={{
              borderRadius: "1rem",
              height: "120px",
              backgroundImage: `url(${grafico2})`,
              backgroundSize: "40% auto",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 24px center",
            }}
          >
            <div className="d-flex flex-column align-items-start">
              <div className="text-black-50">{t("dashboard.state")}</div>
              <h2
                className="mt-1"
                style={{ fontSize: "18px", fontWeight: "600" }}
              >
                {staticDatas ? staticDatas.normas_estadual : <Loading />}
              </h2>
            </div>
          </div>
        </div>
        {storedProdutoAtivo !== "latam" && (
          <div className="col-4">
            <div
              className="p-4 bg-white border-0 shadow-sm text-center d-flex justify-content-between"
              style={{
                borderRadius: "1rem",
                height: "120px",
                backgroundImage: `url(${grafico3})`,
                backgroundSize: "40% auto",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right 24px center",
              }}
            >
              <div className="d-flex flex-column align-items-start">
                <div className="text-black-50">{t("dashboard.municipal")}</div>
                <h2
                  className="mt-1"
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  {staticDatas ? staticDatas.normas_municipal : <Loading />}
                </h2>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DadosEstatisticos;
