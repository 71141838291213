import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoBell, GoPeople } from "react-icons/go";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { apiRequest } from "../api/apiRequest";
import bandeiraPt from "../img/flags/64/Brazil.png";
import bandeiraEn from "../img/flags/64/United-Kingdom.png";

function Navbar() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [showNotifications, setShowNotifications] = useState(false);
  const [currentLang, setCurrentLang] = useState(i18n.language || "pt");
  const [calendarData, setCalendarData] = useState([]);
  const [error, setError] = useState(null);

  const formatDate = (dateString) => {
    const [datePart] = dateString.split(" ");
    const [year, month, day] = datePart.split("-");
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const calendarResponse = await apiRequest("GET", `/notificacoes`);

        setCalendarData(calendarResponse.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, []);

  const toggleNotifications = (e) => {
    e.preventDefault();
    setShowNotifications(!showNotifications);
  };

  const toggleLanguage = () => {
    const newLang = currentLang === "pt" ? "en" : "pt";
    i18n.changeLanguage(newLang);
    setCurrentLang(newLang);
  };

  return (
    <div className="row d-flex" style={{ width: "100%" }}>
      <nav
        className="navbar navbar-static-top bg-transparent d-flex flex-row-reverse"
        role="navigation"
        style={{ width: "100%", marginTop: "10px" }}
      >
        <div className="d-flex align-items-center flex justify-end pr-4">
          <div onClick={toggleLanguage} style={{ cursor: "pointer" }}>
            <img
              src={currentLang === "pt" ? bandeiraEn : bandeiraPt}
              alt="flag"
              style={{ width: "28px" }}
            />
          </div>
          {/* Notificacoes */}
          <div className="nav navbar-top-links ml-2">
            <div className="dropdown">
              <a
                className="dropdown-toggle count-info"
                onClick={toggleNotifications}
                href="#"
              >
                <div style={{ position: "relative", display: "inline-block" }}>
                  <GoBell
                    style={{ fontSize: "18px", padding: "0", color: "#232730" }}
                  />

                  {calendarData.total > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        top: "-5px",
                        left: "-5px",
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#043A5E",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        fontSize: "10px",
                        fontWeight: "bold",
                        border: "2px solid white",
                      }}
                    >
                      {calendarData.total}
                    </div>
                  )}
                </div>
              </a>

              {showNotifications && calendarData.eventos.length > 0 && (
                <table
                  className="dropdown-menu dropdown-alerts"
                  style={{
                    display: showNotifications ? "block" : "none",
                    width: "300px",
                    height: "100",
                  }}
                >
                  <thead className="bg-light">
                    <tr>
                      <th
                        className="pl-3 font-weight-light"
                        style={{ width: "65%" }}
                      >
                        {t("dashboard.nextEvent")}
                      </th>
                      <th className="font-weight-light">
                        {t("dashboard.date")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {calendarData.eventos.map((item) => (
                      <tr
                        style={{
                          borderTop: "1px solid #e7eaec",
                        }}
                      >
                        <td className="pl-3 font-weight-light">
                          <span
                            style={{
                              display: "-webkit-box",
                              "-webkit-box-orient": "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              "-webkit-line-clamp": "2",
                              lineHeight: "1.5em",
                              maxHeight: "3em",
                              color: "#6C737F",
                            }}
                          >
                            {item.title}
                          </span>
                        </td>
                        <td className="font-weight-light">
                          {formatDate(item.start)}
                        </td>
                        <hr />
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="pl-2 mt-2 d-flex">
                    <div
                      style={{
                        marginBottom: "1rem",
                        display: "flex",
                      }}
                    >
                      <Link
                        to={"/calendario"}
                        style={{ color: "inherit", textDecoration: "none" }}
                      >
                        {t("dashboard.seeMore")}
                      </Link>
                      <div
                        className="ml-1 d-flex justify-content-start align-items-center"
                        style={{ fontSize: "1.3rem" }}
                      >
                        <IoIosArrowRoundForward />
                      </div>
                    </div>
                  </tfoot>
                </table>
              )}
            </div>
          </div>
          <a href="/#/profile">
            <GoPeople style={{ fontSize: "18px", marginLeft: "10px" }} />
          </a>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
