import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiRequest } from "../../api/apiRequest";
import Loading from "../Loading";

function PostsHome() {
  const { t } = useTranslation();
  const [postsData, setPostsData] = useState(null);
  const [error, setError] = useState(null);

  const formatDate = (dateString) => {
    const [datePart] = dateString.split(" ");
    const [year, month, day] = datePart.split("-");
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchPostsData = async () => {
      try {
        const postsResponse = await apiRequest(
          "GET",
          `/dashboard/posts?limite_quantidade=3`
        );

        setPostsData(postsResponse.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchPostsData();
  }, []);

  if (error) return <p className="mx-3">{t("dashboard.errorPosts")}</p>;

  return (
    <div className="">
      <h3 className="p-3">{t("dashboard.latestPosts")}</h3>

      {postsData && postsData.posts ? (
        postsData.posts.map((item) => (
          <a
            href={item.url_origem}
            target="_blank"
            style={{ color: "#676a6c" }}
          >
            <div
              className="border-0 shadow-sm w-100 bg-white px-4 py-4 mb-3 d-flex flex-column align-items-start"
              style={{ borderRadius: "1rem" }}
            >
              <div
                className="px-3 py-1 text-white"
                style={{
                  backgroundColor:
                    item.tipo === "roteiro" ? "#043A5E" : "#429A85",
                  borderRadius: "1rem",
                  fontWeight: "600",
                }}
              >
                {item.tipo.charAt(0).toUpperCase() + item.tipo.slice(1)}
              </div>
              <h2 className="" style={{ fontSize: "20px", fontWeight: "600" }}>
                {item.titulo}
              </h2>
              <div className="w-100 d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-start align-items-center">
                  <div
                    className="ml-2"
                    style={{ fontSize: "11px", fontWeight: "400" }}
                  >
                    {formatDate(item.criado)}
                  </div>
                </div>
              </div>
            </div>
          </a>
        ))
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default PostsHome;
