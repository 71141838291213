import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { apiRequest } from "../../api/apiRequest";
import Loading from "../Loading";

function UltimosReports() {
  const { t } = useTranslation();
  const [normasData, setNormasData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const formatDate = (dateString) => {
    const [datePart] = dateString.split(" ");
    const [year, month, day] = datePart.split("-");
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apresentacoesResponse = await apiRequest(
          "GET",
          `/apresentacoes?pagina=0&limite_quantidade=5&full_info=false&status_envio=enviado`
        );
        setNormasData(apresentacoesResponse.data.newsletters);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error)
    return (
      <p style={{ paddingBottom: "12px", paddingLeft: "12px" }}>
        {t("dashboard.errorReports")}
      </p>
    );

  return (
    <div>
      <div className="">
        <div className="">
          <table className="table table-borderless">
            <thead className="bg-light">
              <tr>
                <th className="pl-3 font-weight-light" style={{ width: "80%" }}>
                  {t("dashboard.tableLastAnalyses.name")}
                </th>
                <th className="font-weight-light">
                  {t("dashboard.tableLastAnalyses.date")}
                </th>
              </tr>
            </thead>
            <tbody>
              {normasData && normasData.length > 0 ? (
                normasData.map((item) => (
                  <tr key={item.id}>
                    <td className="pl-3 font-weight-light">
                      <Link
                        to={`/documento/apresentacao/${item.id}`}
                        style={{ color: "#232730" }}
                      >
                        {item.assunto}
                      </Link>
                    </td>
                    <td className="font-weight-light">
                      {formatDate(item.enviado)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    {t("dashboard.noReportsFound")}
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot className="pl-4 mt-2 d-flex"></tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

export default UltimosReports;
