import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsClipboardData } from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import { FaBookBookmark } from "react-icons/fa6";
import { GoPeople } from "react-icons/go";
import { PiNotebook } from "react-icons/pi";
import { RiCheckboxMultipleLine, RiHomeSmile2Line } from "react-icons/ri";
import { TfiViewListAlt } from "react-icons/tfi";
import { Link, useLocation } from "react-router-dom";
import { apiRequest } from "../api/apiRequest";
import logo from "../img/logo-taxupdate.png";

function SideMenu() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  const [produtoAtivo, setProdutoAtivo] = useState("");
  const storedProdutoAtivo = localStorage.getItem("produto_ativo");

  useEffect(() => {
    if (storedProdutoAtivo) {
      setProdutoAtivo(storedProdutoAtivo);
    }
  }, [storedProdutoAtivo]);

  const handleAlterProduct = async () => {
    try {
      const response = await apiRequest("post", `/products/alterProduct`);

      const novoProdutoAtivo = response.data.cache.empresa.produto_ativo;
      setProdutoAtivo(novoProdutoAtivo);

      if (response.data.cache.empresa.produto_ativo === "brasil") {
        i18n.changeLanguage("pt");
      } else if (response.data.cache.empresa.produto_ativo === "latam") {
        i18n.changeLanguage("en");
      }

      localStorage.setItem("produto_ativo", novoProdutoAtivo);
      window.location.reload();
    } catch (error) {
      if (error.status === 400) {
        console.log("400");
        alert(t("sideMenu.alertDontHaveLatam"));
        return;
      }
      alert(t("sideMenu.alertError"));
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.hash = "/";
  };

  return (
    <nav className="navbar-default navbar-static-side" role="navigation">
      <div className="sidebar-collapse">
        <a href="/#/dashboard" className="d-flex justify-content-center mt-4">
          <img
            src={logo}
            alt="Imagem decorativa"
            style={{ width: "101px", height: "auto" }}
          />
        </a>

        <ul
          className="nav metismenu pl-2"
          id="side-menu"
          style={{ margin: "8rem 0 0 0" }}
        >
          <li
            className={path === "/dashboard" ? "active mb-2" : "mb-2"}
            key="/dashboard"
          >
            <Link
              to="/dashboard"
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: "3px 0 3px 25px",
                fontWeight: "500",
              }}
            >
              <div className="text-white mr-3" style={{ fontSize: "20px" }}>
                <RiHomeSmile2Line />
              </div>
              {t("sideMenu.dashboard")}
            </Link>
          </li>
          <li
            className={path === "/biblioteca" ? "active mb-2" : "mb-2"}
            key="/biblioteca"
          >
            <Link
              to="/biblioteca"
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: "3px 0 3px 25px",
                fontWeight: "500",
              }}
            >
              <div className="text-white mr-3" style={{ fontSize: "20px" }}>
                <BsClipboardData />
              </div>
              {t("sideMenu.library")}
            </Link>
          </li>
          <li
            className={path === "/calendario" ? "active mb-2" : "mb-2"}
            key="/calendario"
          >
            <Link
              to="/calendario"
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: "3px 0 3px 25px",
                fontWeight: "500",
              }}
            >
              <div className="text-white mr-3" style={{ fontSize: "20px" }}>
                <PiNotebook />
              </div>
              {t("sideMenu.calendar")}
            </Link>
          </li>
          <li className={path === "/data" ? "active mb-2" : "mb-2"} key="/data">
            <Link
              to="/data"
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: "3px 0 3px 25px",
                fontWeight: "500",
              }}
            >
              <div className="text-white mr-3" style={{ fontSize: "20px" }}>
                <RiCheckboxMultipleLine />
              </div>
              {t("sideMenu.statisticalData")}
            </Link>
          </li>
          <li
            className={path === "/apresentacoes" ? "active mb-2" : "mb-2"}
            key="/apresentacoes"
          >
            <Link
              to="/apresentacoes"
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: "3px 0 3px 25px",
                fontWeight: "500",
              }}
            >
              <div className="text-white mr-3" style={{ fontSize: "20px" }}>
                <GoPeople />
              </div>
              {t("sideMenu.monthlyPresentations")}
            </Link>
          </li>
          <li
            className={path === "/informativos" ? "active mb-2" : "mb-2"}
            key="/informaticos"
          >
            <Link
              to="/informativos"
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: "3px 0 3px 25px",
                fontWeight: "500",
              }}
            >
              <div className="text-white mr-3" style={{ fontSize: "20px" }}>
                <TfiViewListAlt />
              </div>
              {t("sideMenu.reports")}
            </Link>
          </li>
          <li
            className={path === "/leituras" ? "active mb-2" : "mb-2"}
            key="/leituras"
          >
            <Link
              to="/leituras"
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: "3px 0 3px 25px",
                fontWeight: "500",
              }}
            >
              <div className="text-white mr-3" style={{ fontSize: "20px" }}>
                <FaBookBookmark />
              </div>
              {t("sideMenu.technicalReadings")}
            </Link>
          </li>
        </ul>
        <div
          className="text-center py-1"
          style={{
            borderRadius: "5px",
            fontSize: "16px",
            fontWeight: "600",
            margin: "8rem 2.5% 0 2.5%",
            backgroundColor: "#F4C70F",
            color: "#043A5E",
            width: "90%",
            cursor: "pointer",
          }}
          onClick={handleAlterProduct}
        >
          {produtoAtivo === "brasil" ? "TaxUpdate LATAM" : "TaxUpdate Brasil"}
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "50px",
            justifyContent: "center",
          }}
        >
          <button
            onClick={handleLogout}
            style={{
              display: "flex",
              alignItems: "center",
              background: "none",
              border: "none",
              color: "#a7b1c2",
              cursor: "pointer",
              fontSize: "12px",
            }}
          >
            <FaArrowLeft style={{ marginRight: "8px" }} /> {/* Ícone de seta */}
            {t("sideMenu.logout")}
          </button>
        </div>
      </div>
    </nav>
  );
}

export default SideMenu;
